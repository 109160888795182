.sign-in {
    width: 100%;
    max-width: 650px;
    position: relative;


    .password-toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: var(--text-color-secondary);
        }
    }

    .sign-in-controls {
        position: fixed;
        max-width: 650px;
        bottom: 0;
        width: calc(100% - 30px);

        sl-button {
            width: 100%;
        }
    
        .sign-in-secondary {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 1rem;
            margin-top: 1rem;
    
            sl-button::part(label) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            img {
                margin-left: 5px;
                margin-bottom: 2px;
            }
        }
    
        .forgot-password {
            margin: 25px auto;
            text-align: center;
            font-weight: 500;
    
            a {
                color: var(--text-color-secondary);
            }
        }
    }
}
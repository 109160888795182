#pricing {
    margin: 100px 0;

    .pricing-block {
        background-color: var(--tile-background);
        border-radius: 25px;
        padding: 50px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .price-dots {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 50px 15px;

            .pricing-dot {
                background-color: var(--background-color);
                border-radius: 50%;
                padding: 0px;
                height: 250px;
                width: 250px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                justify-content: center;

                .savings-chip {
                    background-color: var(--savings-chip);
                    border-radius: 50px;
                    padding: 4px 12px;
                    font-size: 10pt;
                    margin-bottom: 3px;
                    margin-top: 5px;
                }

                .tier-title {
                    font-weight: 600;
                    margin-bottom: 8px;
                    margin-top: 5px;
                }

                .trial-chip {
                    background-color: var(--shoebox-yellow);
                    border-radius: 50px;
                    padding: 4px 16px;
                    margin-bottom: 5px;
                    font-style: italic;
                }

                .dot-price {
                    font-family: 'Reddit Mono', monospace;
                    font-weight: 700;
                    font-size: 48pt;
                    margin: -5px 0;
                }

                .tier-term {
                    color: var(--text-color-tertiary);
                }

                &:first-child {
                    margin-bottom: 25px;
                }
            }

            .or-divider {
                margin-bottom: 25px;
                font-size: 16pt;
                font-weight: 700;
            }
        }

        .product-features {
            h3 {
                font-size: 24pt;
                font-weight: 600;
            }

            .feature-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                
                &:not(:last-child) {
                    margin-bottom: 26px;
                }

                .feature-graphic {
                    font-size: 42pt;
                    font-weight: 600;
                    margin-bottom: -5px;

                    &.unlimited {
                        font-size: 38pt;
                    }

                    &.devices {
                        margin-top: -10px;
                    }

                    svg {
                        margin: 0 -5px;

                        &.phone {
                            font-size: 28pt;
                        }

                        &.tablet {
                            font-size: 40pt;
                            margin-left: -2px;
                        }

                        &.laptop {
                            font-size: 58pt;
                            margin-bottom: -10px;
                            margin-left: 5px;
                        }
                    }
                }

                .feature-description {
                    font-size: 18pt;
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .pricing-block {
            padding: 0;
            padding-bottom: 50px;
            flex-direction: column;
    
            .price-dots {
                align-items: center;
                flex-direction: row;
                padding: 50px 15px;

                .pricing-dot:first-child {
                    margin-bottom: 0;
                }

                .or-divider {
                    margin-bottom: 0;
                    margin-top: 150px;
                }
            }
        }
    }

    @media only screen and (max-width: 625px) {
        .pricing-block {
    
            .price-dots {

                .pricing-dot {
                    height: 200px;
                    width: 200px;
    
                    .trial-chip {
                        font-size: 10pt;
                    }
    
                    .dot-price {
                        font-size: 36pt;
                    }
    
                    .tier-term {
                        font-size: 10pt;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .pricing-block {
    
            .price-dots {
                flex-direction: column;
                padding: 50px 15px;

                .pricing-dot {
                    height: 250px;
                    width: 250px;
    
                    .savings-chip {
                        font-size: 10pt;
                    }
    
                    .trial-chip {
                        font-size: 12pt;
                    }
    
                    .dot-price {
                        font-size: 48pt;
                    }
    
                    .tier-term {
                        font-size: 12pt;
                    }
                }

                .or-divider {
                    margin: 15px 0;
                }
            }

            .product-features {
                h3 {
                    font-size: 24pt;
                    font-weight: 600;
                }
    
                .feature-block {
                    padding: 0 12px;
    
                    .feature-description {
                        font-size: 14pt;
                    }
                }
            }
        }
    }
}
.landing-notification-container {
    background-color: var(--tile-background);
    padding: 250px 25px;
    border-radius: 25px;
    width: 100%;
    

    .notification-content {
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        margin: auto;

        h2 {
            font-size: 32pt;
            font-weight: 600;
    
            span {
                color: var(--shoebox-yellow);
            }
        }

        .landing-subheader {
            font-size: 16pt;
        }

        .notification-input {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            input {
                background-color: var(--background-color);
                width: 100%;
                padding-right: 55px;
            }

            input::placeholder {
                color: var(--text-color-tertiary);
            }

            sl-button {
                position: absolute;
                right: 0;
                margin-right: 8.25px;
            }

            sl-button::part(base) {
                padding: 7px;
                height: fit-content;
                border-radius: 50px;
            }

            sl-button::part(label) {
                padding: 0;
                font-size: 14pt;
                color: var(--background-color);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .notification-body {
            margin-top: 25px;
            animation: fade-in-body 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-body {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            small.error {
                color: var(--shoebox-error);
                display: block;
                margin-bottom: 0;
            }
        }

        .notification-body-success {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 25px;
            animation: fade-in-success 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-success {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            svg {
                font-size: 32pt;
                color: var(--shoebox-yellow);
            }

            span {
                color: var(--text-color-main);
                font-size: 16pt;
                margin-top: 15px;
                text-align: center;

                &.add-new-link {
                    display: flex;
                    align-items: center;
                    color: var(--shoebox-yellow);
                    width: fit-content;
                    font-size: 12pt;
                    margin-top: 10px;
                    cursor: pointer;
                    background-color: var(--background-color);
                    padding: 5px 10px;
                    padding-right: 15px;
                    border-radius: 25px;

                    svg {
                        font-size: 15pt;
                        margin-right: 5px;
                    }
                }
            }
        }
    }    
}

@media only screen and (max-width: 1000px) {
    .landing-notification-container {
        padding: 150px 25px;
    }    
}

@media only screen and (max-width: 600px) {
    .landing-notification-container {
        padding: 150px 15px;
    }    
}

@media only screen and (max-width: 400px) {
    .landing-notification-container {
        .notification-content {
    
            h2 {
                font-size: min(32pt, 10vw);
                font-weight: 600;
        
                span {
                    color: var(--shoebox-yellow);
                }
            }
        }
    }    
}
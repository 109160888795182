.expense-form {

    .name-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .date-amount-block {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 12px;
        width: calc(100% - 12px);
        margin-bottom: 30px;

        span {
            display: flex;
            flex-direction: column;
        }

        .expense-amount-input {
            font-family: 'JetBrains Mono', monospace;
        }
    }

    #expense-category-input {
        margin-bottom: 30px;
    }

    .hsa-eligible-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        span {
            margin-top: 33px;
            margin-left: 20px;

            p {
                color: var(--text-color-secondary);
            }

            a {
                display: flex;
                align-items: center;
                color: var(--shoebox-yellow);
                transition: color 0.3s ease;
                cursor: pointer;

                body.hasHover &:hover {
                    color: var(--shoebox-hover);
                }

                svg {
                    margin-left: 3px;
                }
            }
        }
    }

    .insurance-claim-label {
        display: block;
        margin-bottom: 10px;
    }

    .insurance-claim-block {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;

        label {
            white-space: pre-wrap;
        }

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        sl-button {
            margin-left: 12px;
            width: 100%;
        }

        sl-input {
            width: 100%;
        }

        sl-input::part(base) {
            margin-bottom: 0px;
            margin-left: 12px;
            width: calc(100% - 12px);
        }
    }

    .selected-insurance-display {
        display: flex;
        align-items: center;

        small {
            margin-bottom: 0;
            margin-right: 12px;
        }

        img {
            height: 50px;
        }

        .manual-selection-display {
            font-size: 14pt;
            font-weight: 500;
            color: #7a7ae3;
        }
    }

    .insurance-option-tiles {
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: height 0.3s ease, opacity 0.3s ease;
        margin-bottom: 18px;

        &.expanded {
            visibility: visible;
            opacity: 1;
            height: 438px;
            margin-bottom: 30px;
        }

        .insurance-tile {
            outline: 0px solid var(--shoebox-yellow);
            // outline-style: inset;
            border-radius: 15px;
            transition: outline 0.15s ease;

            &.selected {
                outline: 5px solid var(--shoebox-yellow);
            }
        }

        input {
            width: calc(100% - 30px);
        }
    }

    .withdrawn-expense-label {
        margin-bottom: 10px;
        display: block;
    }

    .withdrawn-expense-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        input {
            margin-bottom: 0px;
            margin-left: 12px;
            width: calc(100% - 12px);
        }
    }

    label {
        font-weight: 500;
        font-size: 14pt;
        margin-bottom: 10px;
        color: var(--text-color-main);
        display: flex;
        align-items: center;
    }

    .button-row {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 12px;
        justify-content: space-between;
        width: calc(100% - 12px);
        margin-bottom: 12px;
    }

    .add-tags-btn {
        transition: all 0.3s ease;
    }

    .styled-input-blocks-button {
        height: 85px;

        label {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 12pt;
            background-color: var(--sl-color-info-500);
            border-radius: 15px;
            font-family: 'Inter', sans-serif;
            text-align: center;
            width: 100%;
            height: 100%;
            max-width: 300px;
            cursor: pointer;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-weight: 400;
                margin: 0;
                padding: 0;
                font-size: 13pt;

                svg {
                    font-size: 20pt;
                    margin-top: 2px;
                    margin-bottom: 7px;
                    color: var(--text-color-main);
                }
            }

            transition: background-color 0.4s ease;
            cursor: pointer;

            body.hasHover &:hover {
                background-color: var(--sl-color-info-600);
            }
        }

        input[type="file"] {
            display: none;
            height: 0;
        }
    }

    .add-tags-state {
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        input {
            width: 100%;
            margin: 0 12px;
            min-width: 50px;
        }

        @media only screen and (max-width: 740px) {
            sl-button::part(label) {
                padding: 0 15px;
            }
        }

        .add-tag-plus-btn {
            width: 55px;
            display: flex;
            align-items: center;

            svg {
                font-size: 22pt;
            }
        }

        sl-button::part(label) {
            display: flex;
            align-items: center;
        }
    }

    .tag-list {
        sl-badge {
            margin-right: 10px;
            margin-bottom: 10px;
            animation: fade-in-tag 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-tag {
                0% {
                    transform: translateX(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            svg {
                font-size: 14pt;
                margin-right: -4px;
                padding-left: 5px;
                cursor: pointer;
            }
        }
    }

    .receipt-list {
        display: flex;
        margin-bottom: 18px;

        .expense-receipt {
            height: 175px;
            position: relative;
            margin-right: 12px;
            margin-bottom: 12px;
            animation: fade-in-receipt 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-receipt {
                0% {
                    transform: translateX(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            svg {
                position: absolute;
                right: 0;
                font-size: 16pt;
                background-color: white;
                border-radius: 50px;
                margin: 6px;
                cursor: pointer;
            }

            img {
                border: 2px solid var(--tile-background);
                height: 100%;
                border-radius: 15px;
            }
        }
    }

    .notes-block {
        display: flex;
        flex-direction: column;
    }

    .optional-indicator {
        background-color: var(--sl-color-info-600);
        padding: 2px 10px;
        margin-left: 12px;
        font-size: 12pt;
        border-radius: 5px;
        color: var(--text-color-main);
    }

    .expense-form-error {
        font-family: 'JetBrains Mono', monospace;
        color: var(--shoebox-error);
        padding-bottom: 10px;
        display: block;
    }
}
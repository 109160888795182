.profile-dashboard {
    width: 100%;
    max-width: 650px;

    .title {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        top: 70px;
        width: calc(100% - 50px);
        background-color: var(--background-color);
        z-index: 100;
        max-width: 1250px;

        h1 {
            margin: 0;
        }

        #desktop-sign-out {
            width: fit-content;
        }
    }

    .header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 150px;

        h2 {
            font-size: 36pt;
            font-weight: 200;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 10px;;
        }

        img {
            border-radius: 100px;
            height: 100px;
            width: 100px;
        }
    }

    section {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        .button-row {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 12px;
            justify-content: space-between;
            width: calc(100% - 12px);
            margin-bottom: 12px;
        }

        .tri-button-row {
            display: grid;
            grid-template-columns: 33.3% 33.3% 33.3%;
            column-gap: 12px;
            width: calc(100% - 24px);
        }
    }

    .preferences {
        sl-radio-button::part(button) {
            min-width: 75px;
        }
    }

    .support {
        margin-bottom: 0;
    }
    
    sl-button {
        width: 100%;
    }

    sl-radio-group::part(form-control) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    sl-radio-group::part(form-control-label) {
        margin-bottom: 0;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 740px) {

    .profile-dashboard {
        margin-bottom: 70px;

        .title {
            width: calc(100% - 30px);
        }

        .header {
            margin-top: 130px;
        }

        .support {
            margin-bottom: 30px;
        }
    }
}
.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#fourohfour-header {
    font-size: min(32vw, 125pt);
    font-weight: 900;
    color: var(--button-secondary);
}

#fourohfour-help-text {
    display: flex;
    align-items: center;
    white-space: nowrap;

    a {
        text-decoration: none;
        color: var(--shoebox-yellow);
        display: flex;
        align-items: center;
        margin-left: 5px;
    
        svg {
            font-size: 18pt;
            margin-left: 0px;
        }
    }
}
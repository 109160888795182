.investments-panel {

    .set-investments-btn {
        width: 100%;
    }

    .index-selector {
        span {
            display: block;
            margin-bottom: 12px;
        }
        sl-button {
            width: 100%;
        }
        .index-tiles {
            .button-row {
                sl-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                h2 {
                    font-weight: 900;
                }
                small {
                    margin: 0;
                    line-height: 14pt;
                    height: fit-content;
                    white-space: wrap;
                }
                margin-top: 10px;
                display: grid;
                grid-template-columns: 50% 50%;
                column-gap: 12px;
                justify-content: space-between;
                width: calc(100% - 12px);
                margin-bottom: 12px;
            }
            .index-tile {
                height: 85px;
                background-color: var(--sl-color-info-500);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                outline: 0px solid var(--shoebox-yellow);
                cursor: pointer;
                // outline-style: inset;
                border-radius: 15px;
                transition: outline 0.15s ease;

                &.selected {
                    outline: 5px solid var(--shoebox-yellow);
                }
            }
        }

        @media only screen and (max-width: 475px) {
            .index-tiles {
                .button-row {
                    grid-template-columns: 1fr;
                    row-gap: 12px;
                    column-gap: 0;
                    width: 100%;
                }
            }
        }
    }

    .pro-portfolio-selector {
        display: grid;
        width: calc(100% - 12px);
        grid-template-columns: 50% 50%;
        column-gap: 12px;
        animation: fade-in-pro-option-buttons 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-pro-option-buttons {
            0% {
                transform: translateY(10px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }
        
        span {
            text-align: center;
            width: 100%;

            sl-button {
                width: 100%;
            }

            small {
                margin-top: 5px;
            }
        }
    }

    .selected-index-card {
        background-color: var(--tile-background);
        padding: 15px;
        border-radius: 15px;

        .selected-index-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .index-header-starter-badge {
                background-color: var(--button-secondary);
                padding: 5px 15px;
                font-weight: 500;
                border-radius: 25px;
            }
        }

        .selected-index-body-text {
            font-size: 14pt;
            margin-top: 10px;
            display: block;
        }

        h2 {
            font-weight: 900;
        }
    }

    .index-selection-buttons {
        display: grid;
        width: calc(100% - 12px);
        grid-template-columns: 50% 50%;
        column-gap: 12px;
    }

    .edit-index-buttons {
        display: grid;
        grid-template-columns: 50% 50%;
        width: calc(100% - 12px);
        column-gap: 12px;
        margin-top: 12px;

        &.pro-edit-option {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
        }

        #pro-upgrade-marker {
            font-weight: 900;
        }
    }
}
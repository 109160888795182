.portfolio-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dashboard-icon {
        height: 38px;
        padding: 0 3px;
        margin-bottom: 27px;
        cursor: pointer;
    }
}

.unconfigured-portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    text-align: center;
    animation: fade-in-splash 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
    .dashboard-icon {
        height: 43px;

        &.new-folio-header {
            height: 53px;
        }
    }

    @keyframes fade-in-splash {
        0% {
          -webkit-transform: translateY(20px);
                  transform: translateY(20px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
    }

    .dashboard-icon {
        font-size: 40pt;
        color: var(--shoebox-yellow);
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 500;
    }

    span {
        color: var(--text-color-secondary);
        margin-top: 5px;
        margin-bottom: 25px;
    }
}

.account-overview {
    span {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit-account-value {
            color: var(--shoebox-yellow);
            font-weight: 400;
            margin-right: 5px;
            transition: color 0.3s ease;
            cursor: pointer;

            body.hasHover &:hover {
                color: var(--shoebox-hover);
            }
        }
    }
    .account-value-window {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24pt;
        font-family: 'JetBrains Mono', monospace;
        background-color: var(--tile-background);
        margin-bottom: 30px;
        padding: 10px 15px;
        border-radius: 15px;
    }
}

.contributions-configuration, .investments-configuration {
    margin-bottom: 30px;
    
    small {
        margin-bottom: 10px;
        display: block;
    }
}

@media only screen and (max-width: 740px) {
    .portfolio-header {
        width: calc(100% - 30px);
    }
}
.expense-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        font-size: 28pt;
        padding: 0 3px;
        margin-bottom: 17px;
        color: var(--shoebox-yellow);
        cursor: pointer;
        transition: all 0.4s ease;

        &.open-view {
            transform: rotate(45deg);
            color: var(--button-secondary);
        }
    }

    h1 {
        margin-bottom: 15px;
    }
}

.expense-detail {
    width: calc(100% - 30px);
    max-width: 520px;
    margin-top: 150px;
    background-color: var(--tile-background);
    border-radius: 25px;
    padding: 25px;
    height: fit-content;

    .detail-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
            font-size: 24pt;
        }

        .detail-card-actions {
            display: flex;
            align-items: center;

            .close-detail {
                transform: rotate(45deg);
                font-size: 28pt;
                margin-left: 8px;
                color: var(--button-secondary);
                cursor: pointer;
            }
        }
    }

    .detail-body {
        display: flex;
        flex-direction: column;

        small {
            margin-top: 20px;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 14pt;
            font-weight: 600;

            &.currency {
                font-size: 18pt;
                font-family: 'JetBrains Mono', monospace;
            }
        }

        .eligible-check {
            display: flex;
            align-items: center;

            svg {
                font-size: 24pt;
                margin-right: 5px;
                color: var(--shoebox-yellow);

                &.ineligible {
                    color: var(--button-secondary);
                }
            }
        }

        .tags-container {
            sl-badge {
                margin-right: 6px;
                margin-bottom: 6px;
            }
        }

        .notes-section-label {
            margin-top: 14px;
        }

        .no-notes-text {
            color: var(--text-color-secondary);
            font-style: italic;
        }
    }
}

@media only screen and (max-width: 740px) {
    .expense-detail {
        padding: 15px;

        .detail-header {
            h3 {
                font-size: 22pt;
            }
        }
    }
}

.desktop-detail-view {
    display: flex;
    position: fixed;
    height: calc(100dvh - 30px);
    grid-template-columns: 50% 50%;
    margin-top: -50px;
    width: calc(100% - 50px);
    max-width: 1112px;

    .expense-detail {
        margin-top: 170px;
        // 232px = 170 (top margin) + 50 (t&b tile padding) + 12 (spacing margin below card along page bottom)
        max-height: calc(100% - 232px);
        width: calc(50% - 31px);
        max-width: 500px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none; 
    }

    .expense-detail::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (max-width: 740px) {
    .expense-header {
        width: calc(100% - 30px);
    }
}

.expenses-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.empty-expenses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    animation: fade-in-splash 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @keyframes fade-in-splash {
        0% {
          -webkit-transform: translateY(20px);
                  transform: translateY(20px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      

    svg {
        font-size: 40pt;
        color: var(--shoebox-yellow);
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 500;
    }

    span {
        color: var(--text-color-secondary);
        margin-top: 5px;
        margin-bottom: 25px;
    }
}

.edit-drawer-footer {
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% - 12px);
    column-gap: 12px;
}
.schedule-panel {

    .create-schedule-btn {
        width: 100%;
    }

    .editor-panel {
        background-color: var(--tile-background);
        padding: 15px;
        border-radius: 20px;
        margin-bottom: 12px;
        animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-bottom {
            0% {
                transform: translateY(20px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
          }

        .schedule-panel-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            h2 {
                font-size: 16pt;
                font-weight: 500;
            }

            .schedule-action-btns {
                display: flex;
                align-items: center;
                svg {
                    color: var(--button-secondary);
                    font-size: 27pt;
                    margin-left: 6px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    transition: color 0.3s ease;

                    body.hasHover &:hover {
                        color: var(--button-secondary-hover);
                    }
                }
            }
        }

        .contribution-source-toggle::part(button-group) {
            background-color: var(--background-color);
            width: 100%;
            margin-bottom: 30px;
        }

        .contribution-source-option {
            width: 100%;

            &::part(button) {
                width: 100%;
            }
        }

        .schedule-controls {

            .schedule-amount-selectors {
                display: grid;
                grid-template-columns: calc(50% - 6px) calc(50% - 6px);
                column-gap: 12px;
                margin-bottom: 30px;

                .blocks-currency-input {
                    input {
                        width: calc(100% - 45px);
                        padding: 0 22px;
                    }
                    span {
                        height: 72%;
                        margin-top: 4px;
                        font-size: 16pt;
                        font-family: 'JetBrains Mono', monospace;
                    }
                }


                input, sl-select::part(form-control-input) {
                    background-color: var(--background-color);
                }

                input {
                    text-align: center;
                    font-family: 'JetBrains Mono', monospace;
                    font-size: 16pt;
                }

                sl-select::part(display-input) {
                    text-align: center;
                    font-size: 16pt
                }

                sl-select::part(combobox) {
                    background-color: var(--background-color);
                    border-radius: 15px;
                }

                sl-select::part(expand-icon) {
                    display: none;
                }
            }

            .schedule-date-pickers {
                display: grid;
                grid-template-columns: 85px 1fr;
                align-items: center;
                margin-bottom: 12px;

                input {
                    background-color: var(--background-color);
                }
            }

            .end-date-toggle-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                sl-radio-button::part(button) {
                    min-width: 60px;
                }

                .end-date-toggle::part(button-group) {
                    background-color: var(--background-color);
                    margin-left: 12px;
                }
            }
        }
    }

    .otc-btn {
        width: 100%;
    }
}
.edit-add-expense-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        font-size: 28pt;
        padding: 0 3px;
        margin-bottom: 27px;
        color: var(--shoebox-yellow);
        cursor: pointer;
        animation: open-expense-form 0.4s ease both;

        @keyframes open-expense-form {
            0% {
                transform: rotate(0);
                color: var(--shoebox-yellow);
            }
            100% {
                transform: rotate(45deg);
                color: var(--button-secondary);
            }
        }
    }
}
.landing-fold {
    height: calc(100vh - 130px);
    margin-bottom: 70px;

    .hero-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 900px;
        margin: auto;

        img {
            max-height: calc(100vh * 0.75);
            animation: fade-in-hero 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-hero {
                0% {
                  -webkit-transform: translateX(-50px);
                          transform: translateX(-50px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateX(0);
                          transform: translateX(0);
                  opacity: 1;
                }
              }
        }

        .hero-text {
            margin-left: 50px;

            h1 {
                font-size: 44pt;
                font-weight: 500;
                color: var(--shoebox-yellow);
                animation: fade-in-hero-text 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation-delay: 0.2s;


                @keyframes fade-in-hero-text {
                    0% {
                    -webkit-transform: translateX(50px);
                            transform: translateX(50px);
                    opacity: 0;
                    }
                    100% {
                    -webkit-transform: translateX(0);
                            transform: translateX(0);
                    opacity: 1;
                    }
                }
            }

            sl-button {
                margin-right: 12px;
                margin-bottom: 12px;
                animation: fade-in-hero-button 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation-delay: 0.8s;

                @keyframes fade-in-hero-button {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }

                svg {
                    font-size: 21pt;
                    margin-left: 5px;
                }
            }

            sl-button::part(label) {
                display: flex;
                align-items: center;

               
            }
        }
    }
}

@media only screen and (max-width: 875px) {
    .landing-fold {
        .hero-content {
            max-width: 555px;
            img {
                display: none;
            }
    
            .hero-text {
                margin-left: 0px;
    
                h1 {
                    font-size: min(44pt, 9.6vw);
                    width: fit-content;
                }
            }
        }
    } 
}

@media only screen and (max-width: 455px) {
    .landing-fold {
        .hero-content {
    
            .hero-text {
    
                h1 {
                    font-size: min(44pt, 9.3vw);
                }
            }
        }
    } 
}

@media only screen and (max-width: 355px) {
    .landing-fold {
        .hero-content {
    
            .hero-text {
    
                h1 {
                    font-size: min(44pt, 9vw);
                }
            }
        }
    } 
}

#features {
    height: calc(100vh - 180px);
    scroll-margin-top: 100px;

    .feature-tabs-container {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1250px;
        margin: auto;

        sl-tab-group {
            width: 100%;
            height: 100%;
        }

        sl-tab-group::part(base) {
            height: 100%;
        }

        sl-tab-group::part(nav) {
            height: 100%;
            display: flex;
            align-items: center;

            div {
                height: 100%;
            }
        }

        sl-tab {
            height: 15vh;

            svg {
                font-size: 24pt;
            }
        }

        sl-tab::part(base) {
            height: 100%;
            padding-left: 5px;
        }

        sl-tab-panel::part(base) {
            margin-left: 50px;
            background-color: var(--tile-background);
            border-radius: 25px;
            padding: 25px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }

        sl-tab-panel {
            width: calc(100% - 50px);
            max-width: calc(100% - 50px);
            height: 100%;

            img {
                max-height: 100%;
                height: auto;
                max-width: 45%;

                &.web-img {
                    max-width: 55%;
                }
            }

            .feature-content {
                margin-left: 25px;
                margin-bottom: 50px;

                svg {
                    font-size: 32pt;
                    color: var(--shoebox-yellow);
                }

                h2 {
                    font-size: 28pt;
                    font-weight: 600;
                    color: var(--feature-headers);
                    margin-bottom: 12px;
                }

                div {
                    font-size: 16pt;
                }
            }
        }

        @media only screen and (max-width: 800px) {
            sl-tab-panel::part(base) {
                margin-left: 25px;
                padding: 15px;
                flex-direction: column;
            }

            sl-tab-panel {
                width: calc(100% - 25px);
                max-width: unset;
                position: relative;
                
                .panel-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    max-height: calc(100% - 75px);
                    margin: auto;
                    padding: 15px 0;
                }

                img {
                    height: auto;
                    max-width: 100%;
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    object-position: 0 0;

                    &.web-img {
                        aspect-ratio: unset;
                        object-position: unset;
                        margin: auto;
                        margin-top: 35px;
                        max-width: 100%;
                    }
                }
    
                .feature-content {
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: 25px;
                    height: fit-content;
                    z-index: 3;
                    
    
                    h2 {
                        text-align: center;
                        margin-bottom: 25px;
                        padding-top: 25px;
                        margin-top: -60px;
                        width: 100%;
                        background-image: linear-gradient(to top, var(--tile-background), var(--tile-background), var(--tile-background), var(--tile-background-fade));
                    }
    
                    div {
                        font-size: 16pt;
                        max-width: 400px;
                    }
                }
            }
        }

        @media only screen and (max-width: 550px) {

            sl-tab {
                svg {
                    font-size: 18pt;
                }
            }

            sl-tab::part(base) {
                padding-right: 15px;
                padding-left: 0px;
            }

            sl-tab-panel::part(base) {
                margin-left: 15px;
            }

            sl-tab-panel {
                width: calc(100% - 15px);

                img {
                    left: 30px;
                }
            }
        }

        @media only screen and (max-width: 500px) {

            sl-tab-panel {
    
                .feature-content {
    
                    h2 {
                        font-size: 22pt;
                        margin-bottom: 12px;
                    }
    
                    div {
                        font-size: 12pt;
                        max-width: 400px;
                    }
                }
            }
        }
    }
}

#stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    margin: 100px 0px;
    scroll-margin-top: 100px;
    border-radius: 25px;
    background: var(--tile-background);
    background: linear-gradient(25deg, var(--tile-background) 0%, var(--stat-bg) 100%);

    .statVisuals {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        .statCopy {
            text-align: center;
            position: absolute;
            margin-bottom: 225px;
            margin-right: 350px;

            span {
                font-style: oblique;
                font-size: 16pt;
                color: var(--text-color-secondary);
            }

            h2:first-child {
                span {
                    color: #CDCDCD;
                    font-style: normal;
                    font-size: 18pt;
                }
            }

            h2:last-child {
                span {
                    color: var(--shoebox-yellow);
                    font-style: normal;
                    font-size: 24pt;
                }
            }
        }

        img {
            width: 90vw;
            max-width: 800px;
        }
    }

    .statFigures {

        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .barChart {
            width: calc(100% - 30px);
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 14pt;
                color: var(--text-color-secondary);
                width: 100%;
                max-width: 750px;

                sup {
                    font-size: 10pt;
                    color: var(--shoebox-yellow);
                }
            }

            .bar {  
                display: flex;
                align-items: center;
                height: 39px;
                width: 100%;
                max-width: 750px;
                border-radius: 35px;
                background-color: var(--background-color);
                margin-top: 10px;
                margin-bottom: 45px;
            }

            .inner-bar {
                height: 27px;
                width: 20%;
                margin-left: 6px;
                border-radius: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: italic;
                font-size: 11pt;
            }

        }

        .standard-bar {
            .innerColor {
                background-color: var(--chart-neutral-color);
                color: var(--text-color-secondary);
            }
        }

        .shoeboxed-bar {
            .innerColor {
                background-color: var(--shoebox-yellow);
                width: 89%;
                color: var(--button-text-color);
            }
        }

        .statSource {
            font-size: 10pt;
            font-style: oblique;
            color: var(--text-color-secondary);
            // max-width: 800px;
            text-align: center;

            sup {
                font-size: 10pt;
                color: var(--shoebox-yellow);
            }

            a {
                text-decoration: underline;
                color: var(--text-color-secondary);
            }
        }
    }

    
}

@media only screen and (max-width: 850px) {
    #stats {
        
        .statVisuals {
            flex-direction: column;

            .statCopy {
                margin-right: 0;
                margin-bottom: 0;
                position: relative;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    #stats {
        .statFigures {
            .barChart {
                span {
                    font-size: 11pt;
                }

                .bar {  
                    margin-bottom: 25px;
                }
            }
        }
    }
}

#pricing {
    scroll-margin-top: 100px;

    h2 {
        font-size: 32pt;
        font-weight: 600;
        color: var(--shoebox-yellow);
        margin-bottom: 25px;

        span {
            color: var(--text-color-tertiary);
        }
    }

    @media only screen and (max-width: 550px) {
        h2 {
            font-size: min(28pt, 9vw);
            margin-bottom: 12px;
        }
    }
}

#notify-me {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    scroll-margin-top: 100px;
}

#additional-tools {
    min-height: 80vh;
    height: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 100px;
    margin-bottom: 250px;
    scroll-margin-top: 100px;

    svg {
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 25px;
        z-index: 1;
    }

    .additional-tools-content {
        display: block;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 950px;
        padding: 0 15px;
        height: 90%;
        margin: auto;

        .section-one {
            padding-right: 35px;
            width: 420px;

            h2 {
                font-size: 32pt;
                font-weight: 600;
                color: var(--blocks-blue);
                margin-bottom: 25px;
            }

            .app-section {
                width: 400px;
                display: flex;
                justify-content: space-between;
                position: relative;

                img {
                    height: 75px;
                    border-radius: 20px;
                    cursor: pointer;
                }

                .selector-arrow {
                    position: absolute;
                    font-size: 24pt;
                    height: 32px;
                    width: 32px;
                    bottom: -35px;
                    color: var(--blocks-blue);
                    left: 5.375%;
                    transition: all 0.3s ease;
                }
            }

            .description-box {
                margin-top: 45px;
                display: flex;
                flex-direction: column;
                width: 400px;

                label {
                    font-size: 16pt;
                    font-weight: 500;
                    // color: var(--blocks-blue);
                    color: var(--text-color-main);
                    margin-bottom: 6px;
                }

                span {
                    color: var(--text-color-main);
                    margin-bottom: 3px;
                }

                a {
                    color: var(--blocks-blue);
                }
            }
        }

        .section-two {
            padding-left: 35px;
            max-width: 400px;

            img {
                height: 75px;
            }

            h3 {
                font-size: 18pt;
                font-weight: 600;
                margin-bottom: 15px;
            }

            a {
                font-weight: 500;
                color: var(--blocks-blue);
            }
        }
    }

    @media only screen and (max-width: 1050px) {
        .additional-tools-content {
            display: block;
            position: relative;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: space-around;
            max-width: 900px;
            height: 90%;
            margin: auto;
    
            .section-one {
                padding-right: 10px;
                width: 420px;

                h2 {
                    font-size: 28pt;
                }
            }

            .section-two {
                padding-left: 10px;
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .additional-tools-content {
            display: block;
            position: relative;
            flex-direction: column-reverse;
            z-index: 3;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            max-width: 420px;
            height: 100%;
            padding: 50px 15px;
            margin: auto;
    
            .section-one {
                padding-right: 0px;
                width: 100%;
                max-width: 420px;
                padding-top: 50px;

                .app-section {
                    width: 100%; 
                    max-width: 400px;
                }

                .description-box {
                    width: 100%; 
                    max-width: 400px;
                }
            }

            .section-two {
                padding-left: 0px;
                width: 100%;
                max-width: 420px
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .additional-tools-content {

            .section-one {
                display: flex;
                flex-direction: column;

                h2 {
                    text-align: center;
                }

                .description-box {
                    align-items: center;
                    text-align: center;
                }
            }

            .section-two {
                display: flex;
                flex-direction: column;

                img {
                    margin: auto;
                }

                h3 {
                    text-align: center;
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .additional-tools-content {
    
            .section-one {

                h2 {
                    font-size: 22pt;
                }

                .app-section {
                    img {
                        height: 17vw;
                        border-radius: 15px;
                        transition: border 0.3s ease;

                        &.active {
                            border-bottom: 5px solid var(--blocks-blue-hover);
                            border-right: 5px solid var(--blocks-blue-light);
                        }
                    }

                    .selector-arrow {
                        height: 0;
                    }
                }

                .description-box {
                    margin-top: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #additional-tools {
        margin-bottom: 375px;
    }
}
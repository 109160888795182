:root {
    --background-color: #ffffff;
    --text-color-main: #000000;
    --stat-bg: #ffecd0;
    --button-spinner-indicator: #ffe281;
    --button-text-disabled: #1c1c1c;
    --tile-background: #F4F4F4;
    --tile-button-background: #dcdcdc;
    --tile-background-fade: #F4F4F400;
    --tile-hover: #efefef;
    --tabs-shadow: #00000010;
    --overlay-background: #e2e2e280;
    --chart-neutral-color: #D8D8D8;
    --menu-drop-shadow: #00000025;
    --menu-item-inactive: #636363;
    --consistent-main: #000000;
    --button-secondary: #CECECE;
    --button-secondary-hover: #c6c6c6;
    --savings-chip: #97E79F50;
    --shoebox-yellow: #ffc832;
    --shoebox-hover: #fdce4d;
    --text-color-secondary: #8D8D8D;
    --text-color-tertiary: #c2c2c2;
    --feature-headers: #7B7B7B;
    --shoebox-error: #FF5454;
    --shoebox-error-hover: #ff3d3d;
    --shoebox-error-disabled: #ff7676;
    --blocks-blue: #1082FF;
    --blocks-blue-light: #3596ff;
    --blocks-blue-hover: #0065d1;
}

[data-theme="dark"] {
    --background-color: #131313;
    --text-color-main: #EAEAEA;
    --button-text-disabled: #262626;
    --tile-background: #262626;
    --tile-hover: #1f1f1f;
    --tabs-shadow: #181818ab;
    --overlay-background: #2d2d2d80;
    --drawer-shadow: #11111153;
    --menu-item-inactive: #D8D8D8;
    --consistent-main: #131313;
    --button-secondary: #616161;
    --button-secondary-hover: #5b5b5b;
    --shoebox-yellow: #ffc832;
    --shoebox-hover: #F8B600;
    --text-color-secondary: #8D8D8D;
    --feature-headers: #7B7B7B;
    --shoebox-error: #FF5454;
    --shoebox-error-hover: #ff3d3d;
    --shoebox-error-disabled: #ff7676;
}
.compare-sheet {
    h1 {
        margin-bottom: 5px;
    }
    h6 {
        font-size: 18pt;
        font-weight: 300;
    }
    .back-btn {
        display: flex;
        align-items: center;
        margin: 8px 0;
        color: var(--shoebox-yellow);
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
        cursor: pointer;

        svg {
            font-size: 20pt;
        }
    }

    .compare-grid {
    
        .price-grid {

        }

        div:not(.price) {
            height: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 10px;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 15px;
        }

        hr {
            margin-bottom: 15px;
            background-color: var(--tile-hover);
            border: none;
            height: 2px;
        }

        span {
            height: 100%;
            background-color: var(--tile-background);
            border-radius: 15px;   
            font-size: 14pt; 
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 10px;

            &.premium {
                grid-column: 2 / 4;
            }
        }

        .header:not(.price) {
            display: grid;
            height: 85px;;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 10px;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 15px 0;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 900;
                background-color: var(--shoebox-yellow);
                height: 55px;
                border-radius: 15px;   
                font-size: 14pt; 
                padding: 10px;

                &#Pro {
                    font-size: 18pt;
                }
            }
        }

        .price {
            font-size: 24pt;
            font-family: 'JetBrains Mono', monospace;
            margin-bottom: -5px;
            margin-top: -5px;
        }
    }
}
.tabs-container {
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    background-color: var(--background-color);
    box-shadow: 0px -5px 15px 12px var(--tabs-shadow);
    height: 90px;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10pt;
        text-decoration: none;
        color: var(--menu-item-inactive);
        margin-bottom: 5px;

        #dot {
            height: 7px;
            width: 7px;
            margin-bottom: 3px;
            border-radius: 10px;
            background-color: transparent;
        }

        #active-dot {
            height: 7px;
            width: 7px;
            margin-bottom: 3px;
            border-radius: 10px;
            background-color: var(--shoebox-yellow);
        }

        svg {
            font-size: 28pt;
            // margin-bottom: 5px;
        }

        &.active {
            color: var(--text-color-main);
        }
    }
}
.expense-list {
    width: 100%;
    max-width: 550px;
    margin-top: 170px;
    overflow: scroll;
    padding-right: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.desktop-detail-view {
    .expense-list {
        width: calc(50% - 31px);
    }
}

.expense-list::-webkit-scrollbar {
    display: none;
}

.year-header {
    font-size: 22pt;
    font-weight: 900;
    height: 40px;
    padding-top: 10px;
    background-color: var(--background-color);
    color: var(--text-color-main);
    display: flex;
    position: fixed;
    top: 145px;
    width: 100%;
    max-width: 550px;
    z-index: 50;
}

.expense-item {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 12px;
    border-radius: 15px;
    background-color: var(--tile-background);
    transition: background-color 0.3s ease;
    cursor: pointer;

    .expense-summary {
        margin-left: 10px;

        h3 {
            font-size: 14pt;
            font-weight: 600;
        }

        h4 {
            font-size: 11pt;
            font-weight: 400;
            margin-top: 2px;
        }
    }

    .expense-amount {
        display: flex;
        align-items: center;

        font-family: 'JetBrains Mono', monospace;
        font-size: 14pt;

        svg {
            font-size: 22pt;
            margin-bottom: 2px;
            margin-left: 10px;
            color: var(--button-secondary);
            transition: transform 0.3s ease;
        }
    }

    body.hasHover &:hover {
        background-color: var(--tile-hover);

        .expense-amount {
    
            svg {
                transform: translateX(5px);
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .expense-list {
        margin-top: 190px;
    }
}
.global-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 75px;
    padding: 0 25px;
    background-color: var(--background-color);
    z-index: 100;

    .header-content {
        width: 100%;
        max-width: 1250px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;

        .branding {
            display: flex;
            align-items: center;
            cursor: pointer;

            h1 {
                margin: 0;
                font-size: 20pt;
                margin-left: 12px;
            }
        }
    }

    .shoebox-brand {
        height: 45px;
        width: 42px;
    }

    .global-controls {
        display: flex;
        align-items: center;

        .desktop-nav {
            margin-right: 15px;

            a {
                margin-left: 15px;
                color: var(--text-color-main);

                &.active {
                    font-weight: 500;
                    color: var(--shoebox-yellow);
                }
            }
        }
    }

    .profile {
        font-size: 24pt;
        padding: 5px;
        color: var(--menu-item-inactive);
        background-color: transparent;
        border-radius: 50px;
        height: 32px;
        width: 32px;
        transition: background-color 0.4s ease;

        &.active {
            background-color: var(--sl-color-primary-500);
            color: var(--consistent-black);
        }

        body.hasHover &:hover:not(.active) {
            background-color: var(--sl-color-info-500);
        }
    }
}

@media only screen and (max-width: 740px) {
    .global-header {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 800px) {

    .desktop-menu {
        display: none;
    }
  
    .mobile-menu {
      display: flex;
  
        .mobile-header {
            display: flex;
            align-items: center;
        }
  
        .menu-toggle {
            font-size: 20pt;
            cursor: pointer;
        }
  
        .mobile-overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 950;
            height: 100vh;
            width: 100vw;
            background-color: var(--overlay-background);
            backdrop-filter: blur(4px);
            visibility: visible;
            transition: background-color 0.6s ease, backdrop-filter 0.6s ease;
        }
  
        .menu-container {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 955;
            background-color: var(--background-color);
            height: 100vh;
            width: 80vw;
            max-width: 225px;
            transition: right 0.3s ease;
  
            display: flex;
            flex-direction: column;
  
            .close {
                font-size: 20pt;
                
                svg {
                    padding: 20px;
                    cursor: pointer;
                }
            }
  
            nav {
                list-style: none;
                text-align: left;
                padding: 0;
                padding-left: 25px;

                #hidden {
                    display: none;
                }


                a {
                    color: var(--text-color-main);
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    transition: color 0.25s ease;
                    padding: 15px 0;

                    body.hasHover &:hover {
                        color: var(--text-color-secondary);
                    }

                    &.active {
                        color: var(--shoebox-yellow);

                        body.hasHover &:hover {
                            color: var(--shoebox-yellow);
                        }
                    }
                }

                sl-button {
                    padding: 15px 0;
                }
            }
        }
  
        #overlay-hidden {
            background-color: #ffffff00;
            backdrop-filter: blur(0px);
            visibility: hidden;
        }
  
        #container-hidden {
            right: -350px;
        }
    }
  
  }
  
@media only screen and (min-width: 801px) {
    .mobile-menu {
        display: none;
    }
}
  
.desktop-menu {    
        
    nav {
        display: flex;
        list-style: none;
        align-items: center;

        a {
            color: var(--text-color-main);
            transition: color 0.3s ease;
            text-decoration: none;
            margin-left: 30px;

            body.hasHover &:hover {
                color: var(--text-color-secondary);
            }

            &.active {
                color: var(--shoebox-yellow);

                body.hasHover &:hover {
                    color: var(--shoebox-yellow);
                }
            }
        }
    }
}
.setup-splash {
    h1 {
        margin-bottom: 5px;
    }
    h6 {
        font-size: 18pt;
        font-weight: 300;
    }

    sl-drawer {
        --size: 90%;
    }

    sl-drawer::part(panel) {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }

    sl-drawer::part(title) {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
    }

    .automated-list {
        

        div {
            display: flex;
            flex-direction: column;

            .step-title {
                margin-top: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            

            .loader-container {
                padding: 0 15px 0 0px;

                sl-spinner {
                    font-size: 24pt;
                }
            }

            

            .step {
                font-size: 24pt;
                color: var(--tile-hover);
                padding: 0 15px 0 0px;

                &.complete {
                    color: var(--shoebox-yellow);
                }
            }

            .product-label {
                font-weight: 600;

                &.pro {
                    font-weight: 900;
                    text-transform: uppercase;
                }
            }

            .name-form {
                margin-top: 25px;
                animation: fade-in-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                @keyframes fade-in-bottom {
                    0% {
                        transform: translateY(50px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(0);
                        opacity: 1;
                    }
                } 
            }

            .edit-name-toggle {
                margin-left: 10px;
                font-weight: 500;
                color: var(--shoebox-yellow);
                cursor: pointer;
            }

            .view-terms-toggle {
                margin: 25px 0;
            }   
            
        }

        .profile-pic-btns {
            display: flex;
            flex-direction: row;
            margin-top: 25px;
            animation: fade-in-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                @keyframes fade-in-bottom {
                    0% {
                        transform: translateY(30px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

            label {
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--shoebox-yellow);
                border-radius: 15px;
                font-weight: 500;
                font-family: 'Inter', sans-serif;
                text-align: center;
                padding: 0 25px;
                margin-right: 10px;
                height: 50px;

                cursor: pointer;
    
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            sl-button:first-child {
                margin-right: 10px;
            }
    
            input[type="file"] {
                display: none;
                height: 0;
            }            
        }

        .photo-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .photo-editor {
                margin: 15px 0;
                border-radius: 25px;
            }

            .zoom-controls {
                display: flex;
                height: 250px;
                justify-content: space-evenly;
                margin-left: 15px;

                svg {
                    font-size: 32pt;

                    &#profile-image-zoom-out {
                        color: var(--tile-hover);
                    }

                    &#profile-image-zoom-in {
                        color: var(--shoebox-yellow);
                    }
                    
                }
            }
        }

        

        .action-btn {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 15px 25px;
            background-color: var(--background-color);
            width: calc(100% - 50px);

            sl-button {
                width: 100%;
            }
        }

        @media only screen and (max-width: 740px) {
            .action-btn {
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 15px;
                width: calc(100% - 30px);

                sl-button {
                    width: 100%;
                }
            }
        }
    }
}
.create-account {
    h1 {
        margin-bottom: 5px;
    }
    h6 {
        font-size: 18pt;
        font-weight: 300;
    }

    .provider-options {
        padding-top: 15px;

        div {
            background-color: var(--tile-background);
            color: var(--text-color-main);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            margin-top: 15px;
            border-radius: 25px;
            font-size: 16pt;
            font-weight: 600;
            transition: background-color 0.4s ease;
            cursor: pointer;

            img {
                margin-left: 5px;
                height: 30px;
                margin-bottom: 5px;
            }

            body.hasHover &:hover {
                background-color: var(--tile-hover);
            }
        }

        div:last-child {
            background-color: var(--shoebox-yellow);
            color: var(--consistent-main);

            body.hasHover &:hover {
                background-color: var(--shoebox-hover);
            }
        }
    }

    .sign-up-form {
        padding-top: 30px;
    }

    sl-button {
        width: 100%;
        margin-top: 15px;
    }
}


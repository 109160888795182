.blocks-loader {
    .mobile-spinner {
        svg {
            stroke-linecap: round;
        }
    }
}

.blocks-button-loader {

    .mobile-spinner {
        svg {
            stroke-linecap: round;
        }
    }
}